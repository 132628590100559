
import { defineComponent, ref } from "vue"
export default defineComponent({
  props: {
    show: {
      default: false,
      type: Boolean
    },
    title: {
      default: '',
      type: String
    }
  },
  setup(_props, {emit}) {
    const showUpload = ref(true)
    const percent = ref(0)
    const uploadFlag = ref(true)
    const file = ref()
    const uploadLoading = ref(false)
    const handleClose = () => {
      showUpload.value = true
      file.value = undefined
      uploadFlag.value = false
      percent.value = 0
      emit('update:show', false)
    }
    const uploadRequest = (source: any) => {
      showUpload.value = false
      if (source.file) {
        uploadFlag.value = false
      } else {
        uploadFlag.value = true
      }
      file.value = source.file
    }
    const handleUpload = async() => {
      try {
        uploadLoading.value = true
        await new Promise<unknown>((resolve, reject) => {
          emit('upload', file.value, resolve, reject, {
            percent
          })
        })
      } catch (e) {
        showUpload.value = true
        uploadFlag.value = true
        percent.value = 0
      } finally {
        uploadFlag.value = true
        uploadLoading.value = false
      }
    }
    return {
      handleClose,
      uploadRequest,
      handleUpload,
      showUpload,
      uploadFlag,
      percent,
      uploadLoading
    }
  }
})
